<template>
  <base-layout>
    <div class="card">
      <h5 class="card-header fw-bold pt-2 mt-0">{{ exhibitor.short_name || exhibitor.name }}</h5>
    </div>
    <ion-content id="wcpt-exhibitor-detail" class="has-header has-subheader">
        <div class="container-fluid">
          <div class="row row-cols-1 justify-content-start" :class="{'row-cols-md-2' : exhibitor.description}">
            <div class="col-12 px-0  order-2 order-md-1" :class="{'col-md-8': exhibitor.description}">
              <div class="card border-0 px-0 h-100 shadow-sm">
                <div class="card-body">
                  <div class="exhibitor-description-text" v-html="exhibitor.description"></div>
                </div>
              </div>
            </div>
            <div class="col-12 px-0  px-md-0 order-1 order-md-2 " :class="{'col-md-4' : exhibitor.description}">
              <div class="card px-0 h-100 border-0 shadow-sm">
                <div class="card-body px-0 exhibitor-detail" v-if="details">
                  <div class="exhibitor-logo mb-4 mx-auto ">
                    <img :src="'https://fileserver-188ab.kxcdn.com/file/'+ exhibitor.logo.split('/')[4]"
                         v-if="exhibitor.logo">
                  </div>

                  <div class="col border-bottom d-none">
                    <div class="row mb-1 px-2 mb-3 mx-auto">
                      <div class=" col px-2 px-md-1 ">
                        <FavoriteButton :fav-id="favId" v-if="exhibitor.id"></FavoriteButton>
                      </div>
                      <div class="col px-2 px-md-1">
                        <NoteButton :fav-id="favId" v-if="exhibitor.id"></NoteButton>
                      </div>
                    </div>
                  </div>
                  <div class="booth-buttons">
                    <template v-if="details && details.virtualbooth && details.virtualbooth.length > 0">
                      <div class="exhibitor-btn" v-for="booth in details.virtualbooth" :key="booth.id">
                        <a style="text-decoration:none;color:#fff;font-size:0.9rem"
                           v-if="isExternalLink(booth.media_file)" :href="booth.media_file" target="_blank"
                           @click="trackWebsite(booth.media_file);trackUserEvent(booth);">{{ booth.description }}</a>
                        <!--a style="text-decoration:none;color:#fff;font-size:0.9rem"
                           v-if="!isExternalLink(booth.media_file)"
                           @click="trackWebsite(booth.media_file);trackUserEvent(booth);openLink(booth.media_file)">{{
                            booth.description
                          }}</a-->
                        <a style="text-decoration:none;color:#fff;font-size:0.9rem"
                           v-if="!isExternalLink(booth.media_file)" :href="booth.media_file"
                           @click="trackWebsite(booth.media_file);trackUserEvent(booth);">{{ booth.description }}</a>
                      </div>
                    </template>
                  </div>

                  <div class="col mt-1 pt-0 pb-0 ps-2" v-if="exhibitor.homepage && exhibitor.homepage.length > 0">
                    <h6 class="fw-bold ps-2"> {{ wordings[currentLanguage].EXHIBITOR_WEBSITE }}</h6>
                  </div>
                  <div class="col border-bottom pb-2 ps-3 " style="font-size:0.9rem !important;"
                       v-if="exhibitor.homepage && exhibitor.homepage.length > 0">
                    <a style="text-decoration: none; " :href="exhibitor.homepage" target="_blank">
                      <span> {{ exhibitor.homepage }} </span>
                    </a>
                  </div>


                  <div class="col mt-1 pt-0 pb-0 ps-2" v-if="details.locations && details.locations.length > 0">
                    <h6 class="fw-bold ps-2"> {{ wordings[currentLanguage].VISIT_BOOTH }}</h6>
                  </div>

                  <div class="col  ps-2 pb-2" v-if="details.locations && details.locations.length > 0">
                    <div v-for="location in details.locations" :key="location.id" class="mt-3">
                      <router-link :to="'/app/locationmap/' + location.id">
                        <div class="ps-2">
                          <i class="icon ion-location"></i>
                          <span style="margin-left:10px; font-size:0.9rem !important;"> {{ location.name }}</span>
                        </div>
                      </router-link>
                    </div>
                  </div>

                  <template
                      v-if=" exhibitor.email ||  exhibitor.phone ||  exhibitor.street ||  exhibitor.city ||  exhibitor.country">
                    <div class="col mt-1 pt-0 pb-0 ps-2">
                      <h6 class="fw-bold ps-2">{{ wordings[currentLanguage].CONTACT_US }}</h6>
                    </div>
                    <div class="contact-area px-2 mx-2" style="font-size: 0.9rem">
                      <div class="contact" style="display: flex;flex-flow: column;">
                        <!--:href="'mailto:' + exhibitor.email"-->
                        <a class="contact-item" style="white-space:normal" @click="openMail(exhibitor.email)"
                           v-if="exhibitor.email" target="_system">
                          <i class="icon ion-ios-email-outline" style="margin-right:5px"></i>
                          <span style="display:inline-block;white-space:normal"> {{ exhibitor.email }}</span>
                        </a>
                        <div class="contact-item" v-if="exhibitor.phone">
                          <i class="icon ion-ios-telephone" style="margin-right:5px"></i>
                          <span>{{ exhibitor.phone }}</span>
                        </div>

                      </div>
                      <div class="address">
                        <div class="contact-item">{{ exhibitor.street }}</div>
                        <div class="contact-item">{{ exhibitor.city }}<span v-if="exhibitor.city">, </span>{{
                            exhibitor.zip_code
                          }}
                        </div>
                        <div class="contact-item">{{ exhibitor.country }}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row row-cols-1 justify-content-start"
               :class="{'row-cols-md-2' : details.files && details.files.length > 0 &&  details.videos &&  details.videos.length > 0}">
            <div class="col pt-0 pb-2 ms-1" v-if="showResourceSection">
              <h6 class="fw-bold">{{ wordings[currentLanguage].RES_CENTRE }}</h6>
            </div>
            <!--video container-->
            <div class="col-12 px-2" v-if="details && details.videos"
                 :class="{'col-md-8' :  details.videos &&  details.videos.length > 0}">
              <div class="row row-cols-md-2 g-2 justify-content-start"
                   :class="{'row-cols-md-2' : details.videos && details.videos.length > 1}">
                <div class="col mt-0 mb-4" v-for="video in details.videos" :key="video.id">
                  <div class="card h-100 border-0 shadow mx-1">
                    <figure class="figure mx-0 my-auto">
                      <div class="stream">
                        <iframe :src="video.media_file" :id="video.id" :title="video.description"
                                style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
                                allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                      <figcaption class="figure-caption p-1">{{ video.description }}</figcaption>
                    </figure>
                  </div>
                </div>
              </div>

            </div>
            <!--file container-->
            <div class="col-12  mt-4 ms-0 ms-md-0 mt-md-0"
                 :class="{'col-md-4' :  details.files &&  details.files.length > 0}">
              <div class="row row-cols-2 row-cols-md-2 g-3 pt-2 justify-content-start"
                   :class="{'row-cols-sm-2' : details.files && details.videos}">

                <div class="col mt-0  file-item mb-4" v-for="file in details.files" :key="file.id">
                  <div class="card  border-0 shadow">
                    <figure class="figure mx-0 my-auto " @click="handleFile(file,true)"
                    >
                      <!--/face/220/305-->
                      <div class="ratio-4x3">
                        <img :src="file.media_file + '-thumb'" style="object-fit: cover"
                             v-if="!file.icon">
                      </div>
                      <figcaption class="figure-caption file-description p-1">{{ file.description }}

                      </figcaption>

                    </figure>
                    <div class="card-footer p-0">
                      <div class="download-btn pe-1" v-if="!file.readonly"><a :href="file.media_file" target="_blank"
                                                                              @click="trackUserEvent(file, 'download')">
                        <ion-icon name="download-outline"></ion-icon>
                      </a></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid mb-3">
          <div class="row row-cols-1">
            <div class="col pt-0 pb-1" v-if="details.links && details.links.length > 0">
              <h6 class="fw-bold">Additional Links</h6>
            </div>
            <div v-for="link in details.links" :key="link.id"
                 @click="trackWebsite(link.media_file); trackUserEvent(link)">
              <a style="white-space: normal;margin-right: 20px; cursor: pointer; font-size: 0.9rem"
                 :href="link.media_file" target="_blank">
                {{ link.description || link.media_file }}</a>
            </div>
          </div>
        </div>

    </ion-content>
  </base-layout>
</template>


<script>
import {alertController, IonContent, isPlatform, modalController} from "@ionic/vue";
import ExhibitorRequestModal from "../../components/Exhibitions/ExhibitorRequestModal.vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import PdfModal from "@/views/Industry/PdfModal";
import {addIcons} from "ionicons";
import {downloadOutline} from "ionicons/icons";
import FavoriteButton from "@/components/favorite/FavoriteButton";
import router from "@/router";
import NoteButton from "@/components/favorite/NoteButton";
import {store} from "@/store";

const config = require('@/config')

addIcons({"download-outline": downloadOutline});

export default defineComponent({
  name: "ExhibitorDetail",
  data() {
    return {
      exhibitor: {},
      medias: [],
      openModal: false,
      details: {},
      showResourceSection: false,
      favId: null,
      iframewatcher: null,
      currentActiveElement: null

    };
  },
  components: {
    IonContent,
    FavoriteButton,
    NoteButton
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('chat', ['currentRoom']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),

  },
  methods: {
    ...mapActions("exhibitors", [
      "getExhibitor",
      "getMedias",
      "getDetails"
    ]),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    ...mapActions('chat', ['forceUserCreate', 'enterRoom']),
    ...mapActions("maps", ["getMapFromLocation"]),

    async showModal() {
      const modal = await modalController
          .create({
            component: ExhibitorRequestModal,
            cssClass: 'my-custom-class',
            componentProps: {
              title: 'Request a meeting appointment to ' + this.exhibitor.name,
              content: this.exhibitor
            },
          })
      return modal.present();
    },
    trackWebsite(website) {
      this.trackWithLabel('Website viewed:' + website);


    },
    trackUserEvent(file, action) {
      file.exhibitor = this.exhibitor.id;
      let trackData = {
        user: this.user,
        item: file,
        event: action || 'viewed',
        type: file.type,
        itemId: file.id,
      }
      this.trackEvent(trackData);
    },
    openLink(link) {
      console.log(link)
      let target = isPlatform('android') ? '_system' : '_blank'

      if (new RegExp("^(http|https)://").test(link) || new RegExp("^(mailto):").test(link)) {
        window.open(link, target, 'location=no');
      } else {
        router.push(link);
      }
    },

    openMail(link){
      if (isPlatform('android') && typeof cordova != 'undefined') {

        let ref = cordova.InAppBrowser.open('mailto:' +link, '_system', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line
      }else{
        window.open('mailto:'+link, '_blank');
      }
    },
    isExternalLink(link) {
      return new RegExp("^(http|https)://").test(link)
    },
    getPDFLink(link, rewrite) {
      let reslink = link;
      if (rewrite) {
        reslink = 'https://fileserver.s3-de-central.profitbricks.com/' + link.split('/')[4];//TEST
      }
      return reslink;
    },

    videoClicked(link) {
      alert(link)
    },

    async handleFile(file) {
      let link = this.getPDFLink(file.media_file, true);
      let viewerLink = 'https://pdf.abstractserver.com/viewer/#/' + link;
      console.log(viewerLink)

      this.modal = await modalController
          .create({
            component: PdfModal,
            cssClass: 'pdf-modal',
            componentProps: {
              title: file.description,
              link: viewerLink
            },
          })

      file.exhibitor = this.exhibitor.id;
      let trackData = {
        user: this.user,
        item: file,
        event: 'viewed',
        type: 'document',
        itemId: file.id,
      }
      this.trackEvent(trackData);
      return this.modal.present();
    },

    async startChat() {
      let _exhibUser = {
        id: this.exhibitor.auth_user,
        firstname: 'Industry:',
        lastname: this.exhibitor.name,
        avatar_id: '',
        additional_content: {'usertype': 'industry'}
      }
      await this.forceUserCreate(_exhibUser)
      await this.enterRoom([this.user.id, this.exhibitor.auth_user]);
      this.$router.push('/app/chats/' + this.currentRoom)
    },

    //rudimental tracking for iframe actions
    setupIframeListener() {
      let self = this;
      self.iframewatcher = setInterval(function () {
        var activeE = document.activeElement;
        if (activeE && activeE.tagName == 'IFRAME') {
          if (self.currentActiveElement != activeE.id) {
            self.currentActiveElement = activeE.id;
            let file = {
              id: activeE.id,
              exhibitor: self.exhibitor.id,
              type: 'video',
              media_file: activeE.src,
              description: activeE.title
            };
            let trackData = {
              user: self.user,
              item: file,
              event: 'viewed',
              type: 'video',
              itemId: file.id,
            }
            console.log('action triggered', trackData)
            self.trackEvent(trackData);
          }
        }
      }, 5000);
    },
    async presentNotAllowedAlert(text) {
      let buttons = [];
      if (text && text.length > 0) {
        buttons = [
          {
            text: 'Ok',
            id: 'confirm-button',
            handler: () => {
              console.log('Confirm Okay')
            },
          },
        ]
      } else {
        buttons = [
          {
            text: 'Ok',
            id: 'confirm-button',
            handler: () => {
              console.log('Confirm Okay')
            },
          },
          {
            text: 'Log In',
            id: 'confirm-button',
            handler: () => {
              this.login()
            },
          },
        ]
      }
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Content Restricted',
            message: text && text.length > 0 ? this.wordings[this.currentLanguage].SESSION_NO_ACCESS : 'Please log in to gain access.',
            buttons: buttons
          });
      return alert.present();
    },
    async loginByToken(tokenSent) {
      //check for token in path
      let token = tokenSent
      if (token) {

        localStorage.setItem("x-api-key", token);

        let userRequest = await store.dispatch("auth/GET_USERDATA");
        //this.user = userRequest.data;

        let tmpUser = userRequest.data;

        if (tmpUser) {

          await store.commit("auth/SET_USER", tmpUser);
          localStorage.setItem('user', JSON.stringify(tmpUser));
        }
        //splice the path
        window.history.pushState(
            {},
            document.title,
            '/'
        );
        router.push({name: 'Dashboard'});

      } else {
        router.next();
      }
      router.next();

    },
    async login() {
      console.log("login")
      let self = this
      localStorage.setItem('lastPageBeforeLogin', location.hash);
      //let frontendKey = config.auth_key;
      if (isPlatform('ios') && typeof cordova != 'undefined') {
        let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key + '?logout=true', '_blank', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line

        let redirect = (param) => {
          console.log('PARAM', param)
          if (param && param.indexOf('token=') != -1) {
            let token = param.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        }

        ref.addEventListener('loaderror', function (event) {
          console.log('PARAM', event)
          /*ref.executeScript({code: "document.redirectLink = (document.getElementById('redirect').href);"}, ((link) => {
            redirect(link.toString());
          }));*/
          console.log('PARAM', event.url)
          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });
        ref.addEventListener('loadstart', function (event) {
          console.log('PARAM', event)
          /*ref.executeScript({code: "document.redirectLink = (document.getElementById('redirect').href);"}, ((link) => {
            redirect(link.toString());
          }));*/
          console.log('PARAM', event.url)
          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });


      } else if (isPlatform('android') && typeof cordova != 'undefined') {

        let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key, '_blank', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line
        ref.addEventListener('loaderror', function (event) {

          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });
      } else {
        console.log("essta")
        //window.location.href = "https://auth.documedias.com/application/" + config.auth_dev_key
        window.location.href = "https://auth.documedias.com/application/" + (process.env.NODE_ENV == 'production' ? config.auth_key : config.auth_dev_key) + '?logout=true'

      }

    },

  },
  async created() {
  }
  ,
  watch: {
    '$route':
        {
          immediate: true,
          async handler(route) {
            if (route.name == 'Exhibitors Detail') {
              this.exhibitor = await this.getExhibitor(route.params.id);
              this.favId = 'exhibitors/' + this.exhibitor.id;

              this.details = await this.getDetails(this.exhibitor.id);
              console.log(this.details)
              console.log(this.details.videos)
              this.showResourceSection = this.details && (this.details.videos.length + this.details.links.length + this.details.files.length) > 0;
              this.trackWithLabel(this.exhibitor.name)

              this.setupIframeListener();

              if (!this.user.id) {
                this.presentNotAllowedAlert();
              } else {
                if (this.user && this.user.user_no_virt_access) {
                  this.presentNotAllowedAlert('no permission');
                } else {
                  let trackData = {
                    user: this.user,
                    item: this.exhibitor,
                    event: 'enter',
                    type: 'exhibitor',
                    itemId: this.exhibitor.id,
                  }
                  this.trackEvent(trackData);
                }
              }
            } else {
              if (this.iframewatcher) {
                clearInterval(this.iframewatcher);
              }
              this.uploadTrackingEvents();
            }
          }
        },
    updateFinished: async function (newVal) {
      if (newVal) {
        this.exhibitor = await this.getExhibitor(this.$route.params.id);
        this.favId = 'exhibitors/' + this.exhibitor.id;
        this.details = await this.getDetails(this.exhibitor.id);
        console.log(this.details)
        console.log(this.details.videos)
        this.showResourceSection = this.details && (this.details.videos.length + this.details.links.length + this.details.files.length) > 0;
        if (!this.user.id) {
          this.presentNotAllowedAlert();
        } else {
          if (this.user && this.user.user_no_virt_access) {
            this.presentNotAllowedAlert('no permission');
          }
        }
      }
    },
  }

})
;
</script>
<style lang="scss" scoped>

@media (min-width: 768px) {
  .card, .card-header, .card-body, .card-footer {
    border-radius: 0px !important;
  }
  .pdf-modal {
    --width: 80%;
    --height: 80%;
  }
}


.stream {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
}

.exhibitor-description-text {
  font-size: 0.9rem;

  a {
    color: blue !important;
  }
}

.file-item {

  .file-description {
    line-height: 1rem;
  }

  .download-btn {
    text-align: right;
    font-size: 1.2rem;
    width: 100%;
    margin: auto;
  }
}

.exhibitor-logo {
  display: block;
  max-width: 90%;
  text-align: center;

  img {
    max-height: 90px;
  }
}

.exhibitor-detail {
  .action-buttons {
    .actn-btn {
      margin: 0;

      .actn-icon {
        font-size: 1.3rem !important;
      }

      .actn-text {
        font-size: 0.7rem !important;
        line-height: 0.8rem !important;
      }
    }

  }

  .booth-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .exhibitor-btn {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1rem;
      background: #3d2e9c;
      color: #fff;
      border-radius: 50px;
      height: 50px;
      width: 200px;
      text-align: center;
      margin: 20px 0px 0px 20px;


    }

    .request-btn {
      margin-top: 0px !important;
      width: 420px;
      display: inline-block;
    }
  }

}


@media(max-width: 576px) {
  .card, .card-header, .card-body, .card-footer {
    border-radius: 0px !important;
  }
  .file-item {
    max-width: 220px
  }
  .exhibitor-logo {
    display: block;
    max-width: 90%;
    text-align: center;

    img {
      max-height: 90px;
    }
  }
}


</style>
